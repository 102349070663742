<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>财务中心</a-breadcrumb-item>
      <a-breadcrumb-item>合同管理</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canExcelexport">
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <!-- <a-range-picker style="width:240px" allowClear :ranges="rangesData" :disabled-date="disabledDate" v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/> -->
              <a-range-picker style="width:240px" allowClear :ranges="rangesData" :disabled-date="disabledDate" v-model="searchData.created_at" :placeholder="['开始时间', '结束时间']"/>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名称/手机号/合同号</span>
                </template>
                <a-input v-model.trim="searchData.fuzzy" allowClear placeholder="学员名称/手机号/合同号" style="width: 180px"/>
              </a-tooltip>
            </a-form-item>
            <!-- <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>签约</span>
                </template>
                <a-input v-model.trim="searchData.contract_no" allowClear placeholder="签约合同号" style="width: 160px"/>
              </a-tooltip>
            </a-form-item> -->
            <a-form-item >
              <a-select style="width: 200px" allowClear showSearch @change="changeStudio" v-model="searchData.studio_id" placeholder="签约校区" :filterOption="filterOption">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="合同状态" >
                        <a-select v-model="searchData.contract_status" allowClear placeholder="请选择" mode="multiple">
                          <a-select-option v-for="(d, index) of contractStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of categorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="合同类型" >
                        <a-select allowClear v-model="searchData.contract_type" placeholder="请选择">
                          <a-select-option v-for="(d, index) of contractTypes" :key="index" :value="d['contract_type']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      
                      <a-form-item class="block-line" label="课程顾问" > 
                        <a-select mode="multiple" allowClear showSearch v-model="searchData.course_consultant_id" placeholder="请选择" :filterOption="filterOption">
                          <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="来源渠道">
                        <a-tree-select
                            v-model="searchData.channel_id"
                            :tree-data="channels"
                            tree-checkable
                            allowClear
                            treeNodeFilterProp="title"
                            :maxTagCount='1'
                            :maxTagTextLength='5'
                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                            :show-checked-strategy="SHOW_ALL"
                            placeholder="请选择来源渠道"
                        />
                    </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="contract_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1800 }"  @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="studentName" slot-scope="text, record, index">
            <span v-if="index+1 == list.length"></span>
            <LUser v-else :data="record"/>
          </template>
          <template slot="pdf" slot-scope="text, record">
            <a-icon @click="toPreviewPDF(record)" v-show="record.contract_pdf" type="file-pdf" style="font-size: 18px;vertical-align: middle;cursor: pointer;"  />
          </template>
          <template slot="out_trade_no" slot-scope="text,record">
            <a href="javascript:;" @click="showOrderModal(record)">{{ text }}</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <orderModal v-if="orderVisible && canRead" :isFinance='1' :item="modalData"/>
    <contractPdf v-if="isContractPdf" :pdfPath="pdfPath" />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '160px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '', width:'40px', scopedSlots: { customRender: 'pdf' }, key: 'contract_pdf', fixed: 'left',align:'center'},
  // { title: '手机号码', width: '110px',dataIndex: 'student_cellphone', key: '2' ,fixed: 'left',},
  { title: '签约合同号', width: '150px', dataIndex: 'contract_no', key: '1', scopedSlots: { customRender: 'out_trade_no' },fixed: 'left',},
  { title: '签约校区', dataIndex: 'studio_name', key: '3' ,ellipsis: true,sorter:()=>{},width: '120px'},
  { title: '课程顾问', dataIndex: 'course_consultant', key: 'course_consultant' , ellipsis: true,sorter:()=>{},width: '100px'},
  { title: '课程科目', dataIndex: 'course_cate_id', key: '5' ,ellipsis: true,width: '80px'},
  { title: '签约课时', dataIndex: 'times', key: '10' ,align:"center",width: '90px'},
  { title: '赠送课时', dataIndex: 'gift_times', key: '11' ,align:"center",width: '90px'},
  { title: '签约金额', dataIndex: 'contract_costs', key: '7',align:"center" ,sorter:()=>{},width: '90px'},
  { title: '优惠金额', dataIndex: 'contract_discount', key: '8',align:"center" ,width: '80px'},
  { title: '支付方式', width: '80px',dataIndex: 'trade_type', key: 'trade_type' ,align:"center", ellipsis: true},
  { title: '类型', width: '80px', dataIndex: 'contract_type', key: '13' ,align:'center', ellipsis: true},
  { title: '经办人', width: '80px',dataIndex: 'created_by', key: '14', ellipsis: true},
  { title: '来源渠道', dataIndex: 'channel_id', width: '80px', key: 'channel_id' ,ellipsis: true},
  { title: '状态', dataIndex: 'contract_status', key: 'contract_status' ,ellipsis: true,width:'50px'},
  { title: '备注', width: '80px', dataIndex: 'contract_remark', key: 'contract_remark'},
  { title: '操作时间', width: '180px', dataIndex: 'created_at', key: '15',sorter:()=>{},align:'center'}
]

import C_ITEMS from '@/utils/items'
import orderModal from '@/views/finance/daily/orderModal'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table' 
import moment from 'moment'
import contractPdf from './contractPdf'
import costLogVue from '../../user/profile/costLog.vue'

export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    orderModal,
    contractPdf,
  },

  data() {
    return {
      loading: false,
      exportLoading:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,
      isContractPdf:false,
      pdfPath:'',
      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      courseFees: [],
      categorys: [],
      contractTypes: [],
      consultants: [],
      channels:[],
      channelsId:[],
      searchData: {},
      visible: false,
      refundVisible: false,
      orderVisible: false,
      contractStatus: [
        {label:'作废', value:'-1'},
        {label:'完成', value:'0'},
        {label:'有效', value:'1'},
      ],
      authType:['finance','contract'],
    }
  },
  mixins: [ ranges , authority, tableMixins ],
  created() {
    if(this.$route.query.start_date){
      this.searchData.created_at = [moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
    }
    // console.log(this.searchData.created_at);
    // this.getList()
    this.getStudio()
    this.getCategory()
    this.getContractType()
    this.getConsultant()
    this.getSourceChannel()
  },

  methods: {
    async toPreviewPDF(item){
        this.isContractPdf=true;
        this.pdfPath = item.contract_pdf;
        // console.log(item,this.pdfPath)      
    },
    filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async changeStudio(studio) {
      let res = await this.$store.dispatch('searchCourseFeeAction', { data: {studio_id: studio} })
      this.courseFees = res.data
    },
    async getCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.categorys = res.data
    },
    async getContractType() {
      let res = await this.$store.dispatch('searchContractTypeAction', {})
      this.contractTypes = res.data
    },
    async getConsultant() {
        let res = await this.$store.dispatch('searchConsultantAction', {})
        this.consultants = res.data
    },
    async getSourceChannel(id) {
        let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
        this.channels = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_at') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }

      this.searchParams.search = obj

      if(this.$route.query.start_date){
        this.searchParams.search.start_date = this.$route.query.start_date
        this.searchParams.search.end_date = this.$route.query.end_date
      }
      
      let res = await this.$store.dispatch('contractStudentsAction', { data: this.searchParams })
      this.list = res.items
      this.list.push({
        contract_id:-1,
        student_name:'',
        student_cellphone:'',
        contract_no:'',
        studio_name:'',
        course_consultant:'',
        course_cate_id:'',
        times:res._total.times,
        gift_times:res._total.gift_times,
        contract_costs:res._total.contract_costs,
        contract_discount:res._total.contract_discount,
        trade_type:'',
        contract_type:'',
        created_by:'',
        channel_id:'',
        created_at:'',
      })
      this.pageParams = res._meta
      this.loading = false
    },
    handleChange(pagination, filters, sorter) {
      if(sorter.order){
        if(sorter.order === "ascend"){
          this.searchParams['sort'] = `${sorter.field}`
        }else{
          this.searchParams['sort'] = `-${sorter.field}`
        }
      }else{
        this.searchParams['sort'] = ''
      }
      this.getList()
    },
    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },

    showOrderModal(item) {
      this.modalData = item
      this.orderVisible = true
    },
    hideOrderModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.orderVisible = false
    },
    handleTime(){
      this.$forceUpdate()
    },
    async toExport(){
      this.exportLoading = true
      let obj = {}
      let { searchData } = this
      for (let k in searchData) {
        if (k === 'created_at') {
          obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
          obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
        } else {
          obj[k] = searchData[k]
        }
      }
      let exportData = {
          search:obj,
          sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('contractExportAction', exportData)
      if (res.type.indexOf('json') !== -1) {
          var blob = new Blob([res], {
              type: 'application/json'
          })
          let reader = new FileReader();
          reader.readAsText(blob, 'utf-8')
          reader.onload = () => {
              var temp = JSON.parse(reader.result)
              console.log(temp);
              if(temp.status=='error'){
                  this.$message.error(temp.msg);
                  this.exportLoading = false
                  return false;
              }
          }
      }else{
        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) { 
            const link = document.createElement('a')
            link.download = `合同管理.${moment().format('YYYY.MM.DD')}.xlsx`
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href) 
            document.body.removeChild(link)
        } else { //其他浏览器
            navigator.msSaveBlob(blob, fileName)
        }
      }
      this.exportLoading = false
    }
  }
}
</script>